import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  foodProducts = [];
  grocerProducts = [];
  currentShop: any;

  constructor(private firestore: AngularFirestore) {
  }

  //Get product list once user select shop or restaurant
  async getProducts(shopType, shopId) {
    if (shopType == 'restaurant') {
      this.currentShop = await this.firestore.collection('restaurants').doc(shopId).get().toPromise();
      this.currentShop = this.currentShop.data();
      console.log("currentShop", this.currentShop)
      
      this.firestore.collection('restaurants').doc(shopId).collection('products').get().subscribe(result => {
        if (result.size > 0) {
          this.foodProducts = [];
          result.forEach(resItem => {
            let data = resItem.data();
            data['id'] = resItem.id
            this.foodProducts.push(data)
          })
        } else {
          this.foodProducts = []
        }
      })
    } else {
      this.currentShop = await this.firestore.collection('supermarkets').doc(shopId).get().toPromise();
      this.currentShop = this.currentShop.data();
      console.log(this.currentShop)
      
      this.firestore.collection('supermarkets').doc(shopId).collection('products').get().subscribe(result => {
        if (result.size > 0) {
          this.grocerProducts = [];
          result.forEach(resItem => {
            let data = resItem.data();
            data['id'] = resItem.id
            this.grocerProducts.push(data)
          })
        } else {
          this.grocerProducts = []
        }
      })
    }
  }
}
