import { Component } from '@angular/core';

import { Platform, LoadingController, NavController, AlertController, MenuController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { tap } from "rxjs/operators";
import { UserService } from './service/user.service';

import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { BootService } from './service/boot.service';
import { ProductService } from './service/product.service';
import { OrderService } from './service/order.service';

import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

import firebase from 'firebase/app';
import 'firebase/auth';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { AngularFireAuth } from '@angular/fire/auth';

import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  loading: any;

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private loadingCtrl: LoadingController,
    private firestore: AngularFirestore,
    private navCtrl: NavController,
    public userService: UserService,
    public productService: ProductService,
    private orderService: OrderService,
    private bootService: BootService,
    private screenOrientation: ScreenOrientation,
    private callNumber: CallNumber,
    private alertCtrl: AlertController,
    private auth: AngularFireAuth
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');

      // Firebase automatic authentication
      this.listenToUserStatusUpdate();

      // Boot the bootService
      this.bootService.boot()
      console.log ("devicePlatform from app.component", this.bootService.devicePlatform)

      // Lock the screen orientation to portrait for login
      setTimeout(() => {
        console.log ("devicePlatform from app.component", this.bootService.devicePlatform)

        if (this.bootService.devicePlatform !== 'web') {
          this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT)
  
          // Default statusBar style depending on dark/light mode selected by the user
          StatusBar.setStyle({ style: Style.Default });
          StatusBar.show();
        }
      }, 500)
    });
  }
  
  // Alert the user with a choice to handle deliveries by Noumo or by themselves
  async selectDeliveryBy() {
    console.info ("--- selectDeliveryBy ---")

    const alert = await this.alertCtrl.create({
      message: await this.translate.get('MENU.DELIVERED_BY_MESSAGE').toPromise(),
      mode: 'ios',
      buttons: [
        {
          text: await this.translate.get('MENU.YOURSELF').toPromise(),
          cssClass: 'secondary',
          handler: () => {
            this.productService.currentShop.deliveryOption = 'self'
            this.firestore.collection(this.userService.userData.role + 's').doc(this.userService.userData.shopId).update({deliveryOption: 'self'})
          }
        },
        {
          text: "Noumo",
          role: 'cancel',
          handler: () => {
            this.productService.currentShop.deliveryOption = 'noumo'
            this.firestore.collection(this.userService.userData.role + 's').doc(this.userService.userData.shopId).update({deliveryOption: 'noumo'})
          }
        }
      ]
    });
    await alert.present();
  }

  // Inform the user he will be redirected to his/her web backoffice to edit shop details
  async doEdit() {
    const alert = await this.alertCtrl.create({
      message: await this.translate.get('MENU.WILLREDIRECT').toPromise(),
      mode: 'ios',
      buttons: [
        {
          text: await this.translate.get('MENU.CANCEL').toPromise(),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: await this.translate.get('MENU.CONFIRM').toPromise(),
          handler: () => {
            window.open('https://pro.noumodelivery.com');
            console.log('Confirm Okay');
          }
        }
      ]
    });
    await alert.present();
  }

  // Use native phone capacities to call support
  doSupport() {
    console.info ("--- doSupport ---")

    this.callNumber.callNumber("+237456455", true)
    .then(res => console.log('Launched dialer!', res))
    .catch(async err => {
      console.log('Error launching dialer', err)
        
      if (this.bootService.devicePlatform !== 'web') {
        await FirebaseCrashlytics.setUserId({userId: firebase.auth().currentUser.uid})
        await FirebaseCrashlytics.setContext({key: 'page', value: 'app-component', type: 'string'})
        await FirebaseCrashlytics.addLogMessage({message: JSON.stringify(err)})
        await FirebaseCrashlytics.recordException({message: 'callNumber Cordova plugin | app.component.ts | doSupport'})
      }
    });
  }

  // Logout from the merchant app
  // Remove any user variable
  doLogout() {
    console.info ("--- doLogout ---")

    // Firebase authentication logout
    this.auth.signOut();
  }

  async listenToUserStatusUpdate() {
    console.log("--- listenToUserStatusUpdate ---");

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.firestore
          .collection("backoffice_users/", ref => ref.where('authId', '==', user.uid))
          .get()
          .subscribe(res => {
            console.log("LoginRes", res.size);

            if (res.size > 0) {
              console.log(res.docs[0].data());
              this.userService.userData = res.docs[0].data();
              this.orderService.getShopOrders();
              this.productService.getProducts(this.userService.userData.role, this.userService.userData.shopId)
              this.navCtrl.navigateRoot("/orders");
        
              console.log ("devicePlatform from app.component", this.bootService.devicePlatform)
              if (this.bootService.devicePlatform !== 'web') {
                // Lock the screen orientation to landscape for app management
                this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE)

                // Request push notifications
                this.bootService.requestPushPermissions()
              }
            }

            // Hide the splash (you should do this on app launch)
            setTimeout(() => {
              SplashScreen.hide();
            }, 1000)
          });
      } else {
        // Hide the splash (you should do this on app launch)
        this.navCtrl.navigateRoot("/login");

        // Lock the screen orientation to portrait for login
        console.log ("devicePlatform from app.component", this.bootService.devicePlatform)
        if (this.bootService.devicePlatform !== 'web')
          this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT)
        
        setTimeout(() => {
          SplashScreen.hide();
        }, 1000)
      }
    });
  }
}
