import { Injectable } from '@angular/core';
import { PushNotifications, Token, PermissionStatus, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';
import { Device, DeviceInfo } from '@capacitor/device';
import { App, AppInfo } from '@capacitor/app';
import { Storage } from '@capacitor/storage';

import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from './user.service';

import { NativeAudio } from '@capacitor-community/native-audio';
import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';

@Injectable({
  providedIn: 'root'
})
export class BootService {
    devicePlatform: "ios" | "android" | "web" = "web"
    applicationVersion: string = "1.4.11"
    liveDeployVersion: string = "0"

  constructor(
    public firestore: AngularFirestore,
    private userService: UserService
  ) {}

  // Called from app.component.ts
  async boot() {
    console.info ("--- BOOT ---")

    await this.getDeviceInfo()

    if (this.devicePlatform !== 'web') {
        this.getApplicationVersion()
        this.initPushNotifications()
        this.prepareNativeAudio()
    }

    // Initialize Crashlytics
    if (this.devicePlatform !== 'web') {
      await FirebaseCrashlytics.setEnabled({enabled: true})
    }
  }

  // Get application version information based on Capacitor Device plugin
  async getApplicationVersion() {
    console.info ("--- getApplicationVersion ---")

    const info: AppInfo = await App.getInfo();
    this.applicationVersion = info.build + "." + this.liveDeployVersion
    console.log("applicationVersion", this.applicationVersion);
  }

  // Get platform imformation based on Capacitor Device plugin
  async getDeviceInfo() {
    console.info ("--- getDeviceInfo ---")

    const info: DeviceInfo = await Device.getInfo();
    this.devicePlatform = info.platform
    console.log("device platform", this.devicePlatform);
  }

  // Load the bell sound for new order alert
  async prepareNativeAudio() {
    console.info ("--- prepareNativeAudio ---")

    NativeAudio.preload({
      assetId: "bell",
      assetPath: "public/assets/sound/desk_bell_2.mp3",
      audioChannelNum: 1,
      volume: 1.0,
      isUrl: false
    })
    .catch(async error => {
      console.error(error)
        
      if (this.devicePlatform !== 'web') {
        await FirebaseCrashlytics.setContext({key: 'page', value: 'boot-service', type: 'string'})
        await FirebaseCrashlytics.addLogMessage({message: JSON.stringify(error)})
        await FirebaseCrashlytics.recordException({message: 'NativeAudio Capacitor preload API  | boot.service.ts | prepareNativeAudio'})
      }
    })
  }

  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  requestPushPermissions() {
    console.info ("--- requestPushPermissions ---");

    PushNotifications.requestPermissions().then((result: PermissionStatus) => {
      // 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied'
      console.log ("result.receive", result.receive)

      if (result.receive === "granted") {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
        // alert('Error on requestPermission: ' + JSON.stringify(result.granted));
        
        console.warn('Error on requestPermission: ', result.receive);
      }
    });
  }
  
  initPushNotifications() {
    console.info ("--- initPushNotifications ---");
    
    // Remove all badges counts
    PushNotifications.removeAllDeliveredNotifications()

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      async (token: Token) => {
        // alert('Push registration success, token: ' + token.value);
        console.log('Push registration success, token: ' + token.value);         
          
        // Register the push notification token in firestore with the new language
        // IF THE USER HAS NOT YET SUBSCRIBED TO PUSH
        // const { value } = await Storage.get({key: 'PUSH_NOTIFICATION_TOKEN'})
        // if (value === null || value != token.value) {
          // Register the language with the device
          // Does the user subscrive for EN or FR content ?
          let newDevice: any = {
            userId: this.userService.userData.shopId,
            userType: "merchant",
            token: token.value,
            platform: this.devicePlatform,
            language: "fr",
            enabled: true,
            lastUpdate: new Date()
          }

          // TODO: Register the token value in firestore devices collection
          await this.firestore
          .collection('devices')
          .doc(token.value)
          .set(newDevice)

          // Register the token value in local Storage
          await Storage.set({ key: 'NOUMO_PUSH_NOTIFICATION_TOKEN', value: token.value });
        // } else {
        //   console.warn ("STORAGE PUSH_NOTIFICATION_TOKEN IS NULL OR !=")
        // }
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        // alert('Error on registration: ' + JSON.stringify(error));
        console.error('Error on registration: ', error);
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(notification));
        
        console.log('Push received: ', notification);
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
        
        console.log('Push tapped: ', notification);
      }
    );
  }
}