import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from './user.service';

import { Haptics } from '@capacitor/haptics';
import {NativeAudio} from '@capacitor-community/native-audio';
import { BootService } from './boot.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  orders_for_now = [];
  orders_for_later = [];
  orders_preparing = [];
  orders_ready = [];
  orders_pickedup = [];
  orders_delivered_recently = [];
  history = [];

  // 1 - the consumer create an order with status « waiting » 

  // A push notification is sent to the merchant app with a sound and from the merchant app the new order appears on the left column within a red box « new order »

  // 2 - The shop owner opens the « new order » by clicking on the red box. Then the order is AUTOMATICALLY accepted with the status « preparing » so the order appears on the left column within the « in preparation » section

  // 3 - The order preparation is finished then the shop owner open the order and click on « ready » then the status of the order changes to « ready »

  // A push notification is sent to the driver app to look for a driver to come, pick up the order and deliver it to the consumer

  // 4 - The driver comes to the shop and pick up the order. He then click on the driver app « order picked up » then the order status changes to  « pickedup » and the order goes on the section « out for delivery »

  // 5 - The order is delivered and the driver click on « delivered » on the driver app. Then the status of the order changed to « delivered ». From the merchant app the order appears on the last section of the left column « recently delivered » where all delivered orders from the current day appear.

  constructor(
    public firestore: AngularFirestore,
    public userService: UserService,
    private bootService: BootService
  ) {
    setInterval(() => this.calcTimer(), 60000)
  }

  // Every minute timer to assess pickup time for each order
  calcTimer() {
    console.info ("-- calcTimer ---")

    this.orders_preparing.map(order => {
      if (typeof order.shopActions === 'undefined') {
        order.shopActions = {
          acceptTimestamp: {seconds: (new Date()).getTime() / 1000},
          delay: 0
        }
      }

      if (order.nowOrLater === 'asap') {
        let offset = (order.shopActions.acceptTimestamp.seconds / 60) + order.shop.statistics.preparationTime + (order.shopActions.delay ? parseInt(order.shopActions.delay) : 0) - (new Date().getTime() / 1000 / 60)
        order.left = Math.ceil(offset)
      } else {
        let offset = (new Date().getTime() / 1000 / 60) - (order.expectedTime.seconds / 60) + (order.shopActions.delay ? parseInt(order.shopActions.delay) : 0)
        order.left = Math.ceil(offset)
      }

      // The remaining preparation time cannot be negative
      if (order.left < 0)
        order.left = 0

      // The pickup date time is based on the left number of minutes
      let timeToPick: Date = new Date()
      timeToPick.setMinutes(timeToPick.getMinutes() + order.left)
      order.timeToPick = timeToPick

      return order;
    })

    // Sort preparing orders by how many minutes left to pickup time from the quick to be prepared at the top of the list
    this.orders_preparing.sort((a, b) => {
      return a.left - b.left
    })

    this.orders_for_later.map(order => {
      order.timeToPick = new Date((order.expectedTime.seconds - (order.delivery ? order.delivery.duration * 60 : 0) - order.shop.statistics.preparationTime * 60) * 1000)
      console.log(new Date().getTime() / 1000);
      console.log(order.expectedTime.seconds);
      console.log(order.timeToPick.getTime() / 1000);
      if ((order.timeToPick.getTime() - new Date().getTime()) <= 0) {
        this.firestore.collection('orders').doc(order['id']).update({
          status: 'preparing',
          nowOrLater: 'asap',
          "shopActions.acceptTimestamp": new Date()
        })
      }
      return order
    })
  }

  // Get all orders for this shop and filter by status
  getShopOrders() {
    console.info ("--- getShopOrders ---")
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    console.log ("yesterday", yesterday)

    this.firestore.collection("orders", ref => ref
    .where('shop.id', '==', this.userService.userData.shopId)
    .orderBy("timestamp", "desc")
    )
    .snapshotChanges()
    .subscribe(firestoreOrders => {
      // Save current waiting orders counter in order to alert the shop owner if a new one arrives
      const currentWaitingOrdersCounter: number = this.orders_for_now.length
      // reset orders arrays
      this.orders_for_now = []
      this.orders_for_later = []
      this.orders_preparing = []
      this.orders_ready = []
      this.orders_pickedup = []
      this.orders_delivered_recently = []
      this.history = []

      // Feed orders array based on order status
      firestoreOrders.forEach((firestoreOrder) => {
        const firestoreOrderId = firestoreOrder.payload.doc.id
        let firestoreOrderData: any = firestoreOrder.payload.doc.data()
        firestoreOrderData.id = firestoreOrderId
        // Filter nowOrders
        if (firestoreOrderData.status === 'waiting' && firestoreOrderData.nowOrLater !== 'later')
          this.orders_for_now.push(firestoreOrderData);
        else if (firestoreOrderData.status === 'waiting' && firestoreOrderData.nowOrLater === 'later')
          this.orders_for_later.push(firestoreOrderData);
        else if (firestoreOrderData.status === 'preparing')
          this.orders_preparing.push(firestoreOrderData);
        else if (firestoreOrderData.status === 'ready')
          this.orders_ready.push(firestoreOrderData);
        else if (firestoreOrderData.mode === 'delivery' && firestoreOrderData.status === 'pickedup')
          this.orders_pickedup.push(firestoreOrderData);
        else if (firestoreOrderData.status === 'canceled')
          this.history.push(firestoreOrderData);
        else if (firestoreOrderData.status === 'delivered') {
          // Filter recently delivered (24 hours)
          if (new Date(firestoreOrderData.timestamp.seconds * 1000) > yesterday)
            this.orders_delivered_recently.push(firestoreOrderData);
          // Feed history orders
          this.history.push(firestoreOrderData);
        }
        else if (firestoreOrderData.mode === 'pickup' && firestoreOrderData.status === 'pickedup') {
          this.history.push(firestoreOrderData);
        }
      })

      console.log ("orders_for_now", this.orders_for_now)
      console.log ("orders_preparing", this.orders_preparing)
      // Filter this.orders_preparing by shopActions.acceptTimestamp

      if (currentWaitingOrdersCounter < this.orders_for_now.length) {
        // Alert the shop owner with a bell sound + haptic vibration
        this.alertShopOwner()
      }

      // Update waiting and prepating orders timers to pickup
      this.calcTimer()
    })
  }

  // Alert the shop owner with a bell sound + haptic vibration
  async alertShopOwner() {
    console.info ("--- alertShopOwner ---")
    console.log ("this.bootService.devicePlatform", this.bootService.devicePlatform)

    // Vibration with Capacitor Haptics plugin
    await Haptics.vibrate();

    // Play a bell sound with capacitor community plugin for ios/android and HTML5 Audio API for web platform
    if (this.bootService.devicePlatform !== 'web') {
      // Capacitor NativeAudio plugin for Android / ios
      NativeAudio.play({
        assetId: 'bell',
        time: 0
      });
      NativeAudio.setVolume({
        assetId: 'bell',
        volume: 1.0,
      });
    } else {
      // HTML 5 Audio API for web platform
      const bellAudioAsset = new Audio('assets/sound/desk_bell_2.mp3');
      bellAudioAsset.play();
    }
  }
}
